.ToTopBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: transparent;
    color: black;
    border: 2px solid black;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.ToTopBtn svg {
    margin: 10px 0 5px 0;
}

@media screen and (max-width: 480px) {
    .ToTopBtn {
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
    }
}