/* Bloquer le scroll sur html et body */
html.loading,
body.loading {
    overflow: hidden !important;
    height: 100vh !important;
    position: fixed !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #0c0513;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-out;
}

.loader-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px; /* Ajustez selon la taille souhaitée */
    height: 100px; /* Ajustez selon la taille souhaitée */
    animation: spin 2s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    80% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

#preloader-none {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-out;
}