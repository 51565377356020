/* Grid container */
.partners-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;
}

/* Style pour la dernière ligne incomplète */
.partners-grid > * {
    justify-self: center;
}

/* Largeur maximale pour chaque carte pour maintenir la cohérence */
.partner-card {
    width: calc(33.333% - 1.334rem); /* Pour avoir 3 cards par ligne avec le gap */
    min-width: 300px;
    max-width: 350px;
    flex-shrink: 0;
}

/* Card styling */
.partner-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease;
}

.partner-card:hover {
    transform: translateY(-5px);
}

.partner-card img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
    background-color: #f5f5f5;
    padding: 1rem;
}

.partner-card-content {
    padding: 1.5rem;
}

.partner-card h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.25rem;
    font-weight: 600;
}

.partner-rating {
    margin-bottom: 1rem;
}

.partner-description {
    color: #666;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.partner-address {
    font-size: 0.875rem;
    color: #777;
}

/* Responsive design */
@media (max-width: 1024px) {
    .partners-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .partners-grid {
        grid-template-columns: 1fr;
    }
}

/* Style du titre */
.title-container {
    text-align: center;
    margin-bottom: 3rem;
}

.title-container h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #1a1a1a;
}

.leaflet-control-attribution {
    font-size: 8px !important;
    opacity: 0.7;
}

/* Style pour le conteneur des étoiles */
.rating-container {
    position: relative;
    display: inline-block;
}

/* Style pour le tooltip */
.rating-tooltip {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
}

/* Petite flèche en bas du tooltip */
.rating-tooltip::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px 4px 0;
    border-style: solid;
    border-color: #333 transparent transparent;
}

/* Style pour le bouton */
.go-to-button {
    display: inline-block;
    background-color: #FF6B6B; /* Ou votre couleur primary */
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 0.375rem;
    margin-top: 1rem;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;
}

.go-to-button:hover {
    background-color: #FF5252; /* Version plus foncée */
    transform: translateY(-2px);
}

.go-to-button:active {
    transform: translateY(0);
}

/* Ajustement du conteneur de la carte pour le scroll */
.map-container {
    scroll-margin-top: 2rem;
}

/* Style pour le conteneur de la popup */
.leaflet-popup-content {
    margin: 8px !important;
    min-width: 200px !important;
}

/* Style pour le contenu de la popup */
.popup-content {
    position: relative;
}

/* Style spécifique pour le bouton dans la popup */
.popup-button {
    font-size: 0.75rem !important;
    padding: 0.25rem 0.75rem !important;
    text-decoration: none !important;
    color: white !important;
    float: right !important;
    margin-top: -8px !important;
}