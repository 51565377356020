.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.info-section {
    margin: 20px 0;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title-2, .title-3 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.desc-2 {
    margin-bottom: 20px;
    font-size: 1.1em;
    text-align: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps {
    margin-bottom: 20px;
    width: 100%;
}

.div-wrapper {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    transition: background-color 0.3s, transform 0.3s;
    width: 80%;
    max-width: 600px;
}

.div-wrapper:hover {
    background-color: #e0e0e0;
    transform: scale(1.02);
}

.why-choose {
    margin-top: 20px;
    width: 100%;
}

.pourquoi-intro {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.pourquoi-image {
    width: 50px; /* Ajustez la taille selon vos besoins */
    height: auto;
    margin-right: 10px;
}

.pourquoi-title {
    font-weight: bold;
    margin: 0;
    font-size: 1.2em;
}

.pourquoi-desc {
    margin: 0;
    font-size: 1em;
}

/* Styles pour le conteneur d'images */
#image-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

#image-container img {
    width: 100px; /* Ajustez la taille selon vos besoins */
    height: auto;
    margin: 0 10px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.hero-section {
    background-color: #ffffff;
    color: #333333;
    padding: 50px 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 20px;
}

.hero-title {
    font-size: 2.5em;
    margin-bottom: 10px;
    font-weight: bold;
}

.hero-subtitle {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #555555;
}

.hero-description {
    font-size: 1em;
    margin-bottom: 20px;
    color: #666666;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.hero-description-2 {
    font-size: 1em;
    margin-top: -15px;
    color: #666666;
}

.features-section, .why-choose-section {
    padding: 40px 20px;
    text-align: center;
}

.section-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.features-container, .why-choose-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.feature, .why-choose-item {
    background-color: #f4f4f4;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 250px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.feature:hover, .why-choose-item:hover {
    transform: scale(1.05);
}

.feature-image, .why-image {
    width: 100px; /* Ajustez la taille selon vos besoins */
    height: auto;
    margin-bottom: 10px;
}

.feature p {
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}
