.pricing-container {
    display: flex;
    /* justify-content: space-around; */
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px 0;
}

.pricing-plan {
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 10px;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pricing-plan h3 {
    color: #800000;
    font-size:x-large;
    text-align: center;
}

.pricing-plan strong {
    text-align: center;
    font-size: 18px;
}

.pricing-plan:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: default;
}

.contact-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #800000;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.contact-button:hover {
    background-color: #a00000;
}

.bottom-div {
    text-align: center;
}