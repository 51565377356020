.contact-form {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.form-row input {
    margin-right: 10px;
}

.form-row input:last-child {
    margin-right: 0;
}

.form-row input,
.form-row textarea {
    width: 48%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Police', sans-serif;
    outline: none;
}

.form-row input:focus,
.form-row textarea:focus {
    border-color: #ccc;
    box-shadow: none;
}

.form-row input.wide,
.form-row textarea.wide {
    width: 100%;
}

textarea {
    height: 100px;
}

.submitBtn {
    width: 100%;
    padding: 10px;
    background-color: #800000;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.submitBtn:hover {
    background-color: #a00000;
}

/* Ajout de styles pour les messages d'erreur */
.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* Styles pour les labels */
label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.form-row select {
    width: 51%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Police', sans-serif;
    margin-right: 10px;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    background-color: #f9f9f9;
    padding-right: 30px;
    position: relative;
    outline: none;
}

.form-row select:focus {
    border-color: #ccc;
    box-shadow: none;
}

.form-row select::after {
    content: '';
}

.form-row select:last-child {
    margin-right: 0;
}

.form-row select:hover {
    cursor: pointer;
}

.telephone-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.country-code {
    margin-right: 10px;
    font-size: 16px;
    line-height: 40px;
}

.telephone-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Ajout des media queries uniquement pour la largeur du conteneur */
@media screen and (max-width: 768px) {
    .contact-form {
        max-width: 85%;
        margin: 30px auto;
    }

    .form-row {
        flex-direction: column;
    }

    .form-row input,
    .form-row select,
    .form-row textarea {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        height: 40px;
        box-sizing: border-box;
    }

    .form-row select {
        width: 100%;
        padding: 10px;
        background-color: white;
        appearance: auto;
    }

    .form-row textarea {
        height: 100px;
    }
}

@media screen and (max-width: 480px) {
    .contact-form {
        max-width: 80%;
        margin: 20px auto;
    }
}
