.sitemap-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.sitemap-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sitemap-container li {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.sitemap-container li:last-child {
    border-bottom: none;
}

.sitemap-container a {
    text-decoration: none;
    color: #a00;
    font-size: 16px;
}

.sitemap-container a:hover {
    text-decoration: underline;
    color: #800;
}

@media (max-width: 768px) {
    .sitemap-container {
        width: 85%;
        padding: 10px;
    }

    .sitemap-container a {
        font-size: 14px;
    }
}
