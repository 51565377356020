.not-found-container {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    padding: 20px;
}

.not-found-content {
    text-align: center;
    max-width: 600px;
}

.error-code {
    font-size: 120px;
    color: #dc3545;
    margin: 0;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.qr-code {
    display: inline-block;
    width: 0.55em;
    height: 0.65em;
    position: relative;
    margin: 0 0.04em;
    transform: translateY(-0.05em);
    overflow: hidden;
}

.qr-code::before {
    content: '';
    display: block;
    width: 120%;
    height: 120%;
    background: url('../Assets/qr-code.png') center center/120% no-repeat;
    filter: brightness(0);
    position: absolute;
    top: -10%;
    left: -10%;
    z-index: -1;
}

.qr-code {
    border: 0.05em solid #dc3545;
    border-radius: 50%/60%;
}

.error-message {
    font-size: 32px;
    color: #343a40;
    margin: 20px 0;
}

.error-description {
    font-size: 18px;
    color: #6c757d;
    margin-bottom: 30px;
}

.error-actions {
    margin-top: 30px;
}

.home-button {
    display: inline-block;
    padding: 12px 30px;
    background-color: #800000;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

.home-button:hover {
    background-color: #ff0000;
    text-decoration: none;
    color: white;
}

@media (max-width: 768px) {
    .error-code {
        font-size: 80px;
    }
}

