.features-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.features-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.features-description {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #666;
}

.features-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature-item {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px;
    transition: transform 0.2s;
}

.feature-item:hover {
    transform: scale(1.02);
}

.feature-image {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
}

/* Ajout des media queries pour les petits écrans */
@media screen and (max-width: 768px) {
    .feature-item {
        width: 85%;
        max-width: 400px;
        padding: 15px;
    }

    .features-title {
        font-size: 1.8em;
    }

    .features-description {
        font-size: 1.1em;
    }
}

@media screen and (max-width: 480px) {
    .feature-item {
        width: 90%;
        max-width: 300px;
        padding: 10px;
    }
}

.contact-section {
    margin-top: 40px;
    text-align: center;
}

.contact-section h3 {
    font-size: 1.8em;
    color: #000000;
}

.contact-section a {
    color: #022244;
    text-decoration: none;
    font-weight: bold;
}

.contact-section a:hover {
    text-decoration: underline;
}

.feature-item h3 {
    margin-top: 0;
}