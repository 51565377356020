.faq-item {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    transition: box-shadow 0.3s;
    position: relative;
}

.faq-item:nth-child(even) {
    transform: translateY(33%);
}

.faq-item:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.faq-item h3 {
    color: #800000;
    margin: 0 0 10px 0;
    font-size: 1.5em;
    font-weight: 900;
}

.faq-item p {
    margin: 0;
    color: #555;
    line-height: 1.5;
}

.search-input {
    width: 30%;
    padding: 10px;
    margin: 20px auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: block;
    outline: none;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 500px;
    width: 90%;
    animation: fadeIn 0.3s;
}

.popup-content h3 {
    margin-bottom: 15px;
    font-size: 2em;
    color: #800000;
}

.popup-content p {
    margin-bottom: 20px;
    color: #333;
}

.popup-content button {
    margin-top: 10px;
    padding: 12px 100px;
    background-color: #800000;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.popup-content button:hover {
    background-color: #a00000;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.faq-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-link {
    margin-top: 20px;
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
}

.contact-link:hover {
    text-decoration: underline;
}

.contact-message {
    margin-top: 60px;
    font-size: 24px;
    color: #333;
    text-align: center;
}

.contact-link {
    font-size: 24px;
    color: #333;
    text-decoration: none;
}

.contact-link:hover {
    text-decoration: underline;
}

.no-scroll {
    overflow: hidden;
}

.highlight {
    background-color: yellow;
}

/* Ajout des media queries pour les petits écrans */
@media screen and (max-width: 768px) {
    .search-input {
        width: 80%;
        font-size: 14px;
    }

    .faq-container {
        grid-template-columns: 1fr;
        gap: 10px;
        padding: 10px;
    }

    .faq-item {
        margin: 5px;
        padding: 15px;
    }

    .faq-item:nth-child(even) {
        transform: none;
    }

    .faq-item h3 {
        font-size: 1.2em;
    }

    .popup-content {
        width: 85%;
        max-width: 350px;
        padding: 20px;
    }

    .popup-content h3 {
        font-size: 1.5em;
    }

    .popup-content p {
        font-size: 0.9em;
    }

    .contact-message {
        font-size: 18px;
        margin-top: 30px;
    }

    .contact-link {
        font-size: 18px;
        text-decoration: underline;
    }
}

@media screen and (max-width: 480px) {
    .search-input {
        width: 90%;
    }

    .popup-content {
        width: 90%;
        max-width: 300px;
        padding: 15px;
    }
}
