footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #800000;
    color: white;
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-links, .social-media {
    width: 45%;
}

.footer-links h4, .social-media h4 {
    margin-bottom: 10px;
}

.footer-links ul, .social-media ul {
    list-style: none;
    padding: 0;
}

.footer-links li, .social-media li {
    margin: 5px 0;
}

.footer-links a, .social-media a {
    color: white;
    text-decoration: none;
}

.footer-links a:hover, .social-media a:hover {
    text-decoration: underline;
}

.footer-minimal {
    padding: 15px;
}

.footer-minimal p {
    margin: 0;
}

footer p {
    padding: 10px 0;
    margin: 0;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        font-size: 0.9em;
    }

    .footer-links, .social-media {
        width: 100%;
        text-align: center;
    }

    footer p {
        font-size: 0.8em;
    }
}
