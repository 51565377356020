.header-container {
    background-color: #800000;
    color: white;
    padding: 20px;
    text-align: center;
    margin: 0;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 100;
}

.hamburger span {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 2px 0;
    transition: 0.3s;
}

.main-nav {
    margin: 10px 0;
    font-size: 1.2rem;
}

.main-nav a {
    margin: 0 15px;
    color: white;
    text-decoration: none;
    transition: color 0.3s, transform 0.3s;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
}

.main-nav a:hover {
    text-decoration: none;
    color: #ff0000bc;
    background-color: rgba(255, 0, 0, 0.2);
    transform: scale(1.05);
}

.langue {
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: transform 0.3s;
}

.langue:hover {
    transform: scale(1.1);
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

@media screen and (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .main-nav {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #800000;
        padding: 20px 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 9999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        transform: translateY(-20px);
    }

    .main-nav.nav-open {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
    }

    .main-nav a {
        display: block;
        margin: 10px 0;
        padding: 10px;
    }

    .header-container {
        padding-bottom: 10px;
    }
}

.hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active span:nth-child(2) {
    opacity: 0;
}

.hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.hidden {
    display: none !important;
}