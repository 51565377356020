@font-face {
  font-family: "Police";
  src: url('../public/Fonts/Butler_Regular.otf');
}

@font-face {
  font-family: "Police Bolder";
  src: url('../public/Fonts/Butler_ExtraBold.otf');
}

@font-face {
  font-family: "Police Bold";
  src: url('../public/Fonts/Butler_Bold.otf');
}

* {
  font-family: 'Police', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

h1, h2, h3 {
  font-family: 'Police Bolder', sans-serif !important;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h2 {
    font-family: 'Police Bolder', sans-serif !important;
}

::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #a00000;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #f00000;
}

textarea {
    resize: none;
}

.no-hover *:hover {
  transform: none !important;
  transition: none !important;
}
