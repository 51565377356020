/* Styles de base pour la page CGV */
.cgv-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.8;
    color: #333;
    background-color: #f9f9f9;
    margin: 20px auto;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 86%;
}

/* Titre principal */
.cgv-container h1 {
    font-size: 2em;
    color: #222;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
}

/* Sous-titres */
.cgv-container h2 {
    font-size: 1.5em;
    color: #0a213a;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 10px;
}

/* Paragraphes */
.cgv-container p {
    font-size: 1em;
    margin-bottom: 15px;
    text-align: justify;
}

/* Listes */
.cgv-container ul, 
.cgv-container ol {
    margin-left: 20px;
    margin-bottom: 15px;
}

.cgv-container ul li, 
.cgv-container ol li {
    margin-bottom: 10px;
}

/* Liens */
.cgv-container a {
    color: #0056b3;
    text-decoration: none;
    transition: color 0.3s ease;
}

.cgv-container a:hover {
    color: #003366;
}

/* Citations */
.cgv-container blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #eef5fb;
    border-left: 5px solid #0056b3;
    font-style: italic;
    color: #555;
}

/* Responsivité */
@media (max-width: 768px) {
    .cgv-container {
        padding: 15px;
        width: 86%;
        max-width: 600px;
    }

    .cgv-container h1 {
        font-size: 1.8em;
    }

    .cgv-container h2 {
        font-size: 1.3em;
    }

    .cgv-container p,
    .cgv-container ul li,
    .cgv-container ol li {
        font-size: 0.95em;
    }

    .cgv-container blockquote {
        margin: 15px 0;
        padding: 8px 15px;
    }
}

@media (max-width: 480px) {
    .cgv-container {
        padding: 10px;
        width: 86%;
    }

    .cgv-container h1 {
        font-size: 1.6em;
    }

    .cgv-container h2 {
        font-size: 1.2em;
        padding-left: 5px;
    }

    .cgv-container p,
    .cgv-container ul li,
    .cgv-container ol li {
        font-size: 0.9em;
    }

    .cgv-container blockquote {
        margin: 10px 0;
        padding: 5px 10px;
        border-left: 3px solid #0056b3;
    }
}
